<template>
  <div class="title">
    <div class="text">
    <h1>{{ title }}</h1>
    <h5>{{ subtitle }}</h5>
    <p><router-link :to="{ name: 'refugees-menu-PT' }">{{$t('document.link')}}</router-link></p>
  </div>
  <img :src="require('@/assets/images/' + img + '')" alt="photo" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    img: String
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.title {
  position: relative;
  width: 100%;
  padding: 20px 120px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.title img{
  margin-left: 30px;
}
.title p {
padding-top: 30px;
text-decoration: underline #fff;
}
.title a {
  color: #fff;
}
.text h1 {
  font-weight: 800;
}
.text h5 {
  font-weight: 500;
  font-size: 17px;
}
@media (max-width: 1000px) {
  .title{
    padding: 0;
    justify-content: space-between;
  }
  .text {
    padding: 20px;
    width: 50%;
  }
  .text h5{
    text-align: left;
  }
  .title img{
    margin-right: 20px;
  }
}
</style>