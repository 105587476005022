<template>
  <div class="community">
    <Header />
    <Policy />
    <TitleRefugeesMenuTemplate
      style="background: #ff9b13"
      :title="title.titlePage"
      :subtitle="title.subtitlePage"
      :img="title.img"
    />
    <InformationsTemplate :card="aspas" />
    <InformationsTemplate :card="juntos" />
    <InformationsTemplate :card="refoodges" />
    <InformationsTemplate :card="refugiados" />
    <InformationsTemplate :card="tamojuntas" />
    <InformationsTemplate :card="outros" />
    <p>
      <router-link :to="{ name: 'refugees-menu-PT' }">{{
        $t("community.linkPage")
      }}</router-link>
    </p>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleRefugeesMenuTemplate from "@/components/templates/TitleRefugeesMenuTemplate";
import InformationsTemplate from "@/components/templates/InformationsTemplate";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";

export default {
  components: {
    Header,
    TitleRefugeesMenuTemplate,
    InformationsTemplate,
    Contact,
    Feedback,
    Footer,
    Policy,
  },
  data() {
    return {
      title: {
        titlePage: this.$t("community.titlePage"),
        subtitlePage: this.$t("community.subtitlePage"),
        img: "community.png",
      },
      juntos: {
        title: this.$t("community.juntosTitle"),
        link: "https://www.facebook.com/agora.juntas",
        linkName: this.$t("community.linkName"),
      },
      refoodges: {
        title: this.$t("community.refoodgesTitle"),
        text: this.$t("community.refoodgesText"),
        link: "https://www.facebook.com/refoodgeesbr/",
        linkName: this.$t("community.linkName"),
      },
      refugiados: {
        title: this.$t("legal.refugiadosTitle"),
        text: this.$t("legal.refugiadosText"),
        link: "https://m.facebook.com/pages/category/Social-Service/refugiados.asvp/posts/",
        linkName: this.$t("legal.crasLinkName"),
      },
      tamojuntas: {
        title: this.$t("community.tamojuntasTitle"),
        link: "https://www.tamojuntas.org.br",
        linkName: this.$t("community.linkName"),
      },
      aspas: {
        title: this.$t("community.aspasTitle"),
        text: this.$t("community.aspasText"),
        link: "https://m.facebook.com/acaosocialpaulovi/",
        linkName: this.$t("community.linkName"),
      },
      outros: {
        title: this.$t("community.outrosTitle"),
        link: "https://asvp.org.br/category/centro-de-apoio-aos-refugiados/",
        linkName: this.$t("community.outrosLinkName1"),
      },
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  margin: 0 0 50px 120px;
}
</style>