<template>
    <div class="card">
      <div class="title">
        <h4>{{card.title}}</h4>
      </div>
      <div class="text">
        <p>{{card.text}}</p><br>
        <a :href="card.link" :target="card.link">{{card.linkName}}</a>
        <a :href="card.link2" :target="card.link2">{{card.linkName2}}</a>
        <a :href="card.link3" :target="card.link3">{{card.linkName3}}</a>
        <a :href="card.link4" :target="card.link4">{{card.linkName4}}</a>
        <br>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        card: Object
    }
};
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.card{
    margin: 20px 120px;
    padding: 20px;
    border-radius: 20px;
    border: none;
    box-shadow: 0 0 10px #c6c6c6;
}
.title h4{
    font-size: 18px;
    font-weight: 800;
    padding-bottom: 20px;
}
.text a{
  display: flex;
  flex-direction: column;
}
@media(max-width: 1000px){
  .card{
    margin: 15px ;
  }
}
</style>